import { HStack, LinkBox, LinkOverlay, ListItem, Tag, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import { startCase } from "lodash"
import React from "react"
import { NavLink } from "react-router-dom"
import { UserFragment, UserStatus } from "../../graphql"
import { UserTag } from "../common"

export type UserListItemProps = {
	user: UserFragment
	onUserClick?: (user: UserFragment) => void
}

export const UserListItem: React.FC<UserListItemProps> = ({ user, onUserClick }) => {
	return (
		<ListItem as={onUserClick ? "li" : LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer" onClick={() => (onUserClick ? onUserClick(user) : undefined)}>
			{!onUserClick && <LinkOverlay as={NavLink} to={`/users/${user._id}`} />}
			<HStack justifyContent="space-between" p="2">
				<UserTag user={user} />

				<VStack align="flex-end">
					<Text fontSize="sm">{startCase(user.roleType)}</Text>
					<Tag size="sm" variant="outline" colorScheme={user.status === UserStatus.Active ? "success" : "error"}>
						{user.status}
					</Tag>
					<Text fontSize="xs" color="grayscale.label">
						{format(new Date(user.createdAt), "MMM dd, yyyy p")}
					</Text>
				</VStack>
			</HStack>
		</ListItem>
	)
}
