import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { ReadersByFilterQueryVariables, ReaderTypes, useReadersByFilterQuery } from "../../graphql"
import { ReadersList } from "./ReadersList"

export type ReadersByFilterProps = {
	keyword?: string
	type?: ReaderTypes
	isActive?: boolean
}

export const ReadersByFilter: React.FC<ReadersByFilterProps> = ({ keyword, type, isActive }) => {
	const [filter, setFilter] = useState({ keyword, type, isActive })

	const [pagination, setPagination] = useState<ReadersByFilterQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useReadersByFilterQuery({
		variables: { filter, pagination },
	})

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter({ keyword, type, isActive })
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword, type, isActive])

	const nextPage = useCallback(() => {
		if (data?.readersByFilter.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data])

	return (
		<VStack w="full" maxW="2xl" align="stretch">
			{fetching && !data?.readersByFilter ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading readers</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.readersByFilter.readers && data.readersByFilter.readers.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.readersByFilter.readers.length || 0} reader
						{(data.readersByFilter.readers.length || 0) > 1 ? "s" : ""}
					</Text>

					<ReadersList readers={data.readersByFilter.readers} loadMore={nextPage} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more readers</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any readers.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
