import { Button, ButtonGroup, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useDeactivateUserMutation, UserFragment } from "../../graphql"
import { UserTag } from "../common"

export type DeactivateUserProps = {
	user: UserFragment
}

export const DeactivateUser: React.FC<DeactivateUserProps> = ({ user }) => {
	const [{ fetching }, deactivate] = useDeactivateUserMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleDeactivate = async () => {
		const { error, data } = await deactivate({ userId: user._id })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.deactivateUser) {
			toast({ description: "Deactivated the user successfully", status: "success" })
			navigate(`/users/${user._id}`)
			return
		} else {
			toast({ description: "Couldn't deactivate the user", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<UserTag user={user} />
			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to deactivate {user.name}?
				</Text>
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/users/${user._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="error" isLoading={fetching} onClick={handleDeactivate}>
						Deactivate
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
