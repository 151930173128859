import { Box, Center, Checkbox, HStack, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"
import { UserRoleTypes, UsersQueryVariables, useUsersQuery } from "../../graphql"
import { UserTag } from "../common"
import { DepartmentSelector } from "./DepartmentSelector"
import { UserRoleTypeSelector } from "./UserRoleTypeSelector"
import { UserTypeSelector } from "./UserTypeSelector"

export type UserSelectorProps = {
	onUpdate: (userIds: string[]) => void
	value: string[]
	roleType?: UserRoleTypes
	userTypeId?: string
	departmentId?: string
}

export const UserSelector: React.FC<UserSelectorProps> = ({ value, onUpdate, departmentId, userTypeId, roleType }) => {
	const [variables, setVariables] = useState<UsersQueryVariables>({ filter: { departmentId: "", userTypeId: "", keyword: "", roleType: undefined }, pagination: { limit: 20, page: 1 } })

	const nextPage = () => {
		if (data?.users.hasNextPage) {
			setVariables((prev) => ({
				...prev,
				pagination: {
					...prev.pagination,
					page: (prev.pagination.page || 0) + 1,
				},
			}))
		}
	}
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			nextPage()
		}
	}, [inViewport])

	const [{ data, fetching, error }] = useUsersQuery({ variables })

	const [keyword, setKeyword] = useState("")

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setVariables((prev) => ({
				...prev,
				filter: {
					...prev.filter,
					keyword,
				},
				pagination: { ...prev.pagination, page: 1 },
			}))
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	const handleChange = (userId: string, isChecked: boolean) => {
		if (isChecked) {
			onUpdate([...value, userId])
		} else {
			const _value = [...value]
			_value.splice(_value.indexOf(userId), 1)
			onUpdate(_value)
		}
	}

	const isUserSelected = useCallback((userId: string) => value.includes(userId), [value])

	const isAllSelected = useMemo(() => data?.users.users.map((u) => u._id).every((uid) => value.includes(uid)), [data, value])
	const isNoneSelected = useMemo(() => !value.length, [data, value])

	console.log(value)

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" flexWrap="wrap" justify="space-between">
				<Input flex="1" variant="filled" bgColor="grayscale.input-background" placeholder="Search" _placeholder={{ color: "grayscale.placeholer" }} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
				<UserRoleTypeSelector
					value={variables.filter.roleType as UserRoleTypes}
					onUpdate={(roleType) => setVariables((prev) => ({ ...prev, filter: { ...prev.filter, roleType: (roleType || undefined) as UserRoleTypes, userTypeId: "" }, pagination: { ...prev.pagination, page: 1 } }))}
					defaultValue={roleType}
				/>
				<UserTypeSelector
					value={variables.filter.userTypeId || ""}
					onUpdate={(userTypeId) => setVariables((prev) => ({ ...prev, filter: { ...prev.filter, userTypeId }, pagination: { ...prev.pagination, page: 1 } }))}
					roleType={variables.filter.roleType as UserRoleTypes}
					defaultValue={userTypeId}
				/>
				<DepartmentSelector
					value={variables.filter.departmentId || ""}
					onUpdate={(departmentId) => setVariables((prev) => ({ ...prev, filter: { ...prev.filter, departmentId }, pagination: { ...prev.pagination, page: 1 } }))}
					defaultValue={departmentId}
				/>
			</HStack>
			{fetching && !data?.users.users.length ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading users</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : !data?.users.users.length ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any user.
					</Text>
				</Center>
			) : (
				<VStack w="full" align="stretch" h="full" maxH={{ base: "sm", xl: "lg" }} overflowY="auto" spacing={0}>
					<Table>
						<Thead pos="sticky" top="0" w="full" zIndex={10} bgColor="white">
							<Tr>
								<Th>
									<Checkbox
										isDisabled={data.users.hasNextPage}
										isChecked={isAllSelected}
										isIndeterminate={!isAllSelected && !isNoneSelected}
										onChange={(e) => {
											e.target.checked ? onUpdate(data.users.users.map((u) => u._id)) : onUpdate([])
										}}
									>
										<Text fontSize="xs" textTransform="lowercase">
											{value.length} selected
										</Text>
									</Checkbox>
								</Th>
								<Th>User</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.users.users.map((user) => (
								<Tr key={user._id}>
									<Td>
										<Checkbox isChecked={isUserSelected(user._id)} onChange={(e) => handleChange(user._id, e.target.checked)} />
									</Td>
									<Td>
										<UserTag user={user} size="sm" />
									</Td>
								</Tr>
							))}
							<Box w="full" h="2" ref={ref as any} />
						</Tbody>
					</Table>
					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more users</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			)}
		</VStack>
	)
}
