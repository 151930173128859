import { Button, ButtonGroup, Center, Flex, Spinner, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { TagStatus, useMeQuery, UserFragment, UserRoleTypes, useTagByIdQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type UserTagDetailsProps = {
	user: UserFragment
	disableActions?: boolean
}

export const UserTagDetails: React.FC<UserTagDetailsProps> = ({ user, disableActions }) => {
	const [{ data: meData }] = useMeQuery()

	const [{ data, fetching, error }] = useTagByIdQuery({ variables: { tagId: user.tagId || "" }, pause: !user.tagId })

	const canAssign = useMemo(() => {
		if (user.tagId) return false

		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(meData?.me, "tags/assign-to-working-user")[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(meData?.me, "tags/assign-to-short-term-user")[0]
		}
	}, [meData, user])

	const canFlush = useMemo(() => {
		if (!user.tagId) return false

		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(meData?.me, "tags/flush-from-working-user")[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(meData?.me, "tags/flush-from-short-term-user")[0]
		}
	}, [meData, user, data])

	const canDeactivate = useMemo(() => {
		if (!user.tagId || data?.tagById?.status !== TagStatus.Active) return false

		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(meData?.me, "tags/deactivate-working-user-tag")[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(meData?.me, "tags/deactivate-short-term-user-tag")[0]
		}
	}, [meData, user, data])

	const canActivate = useMemo(() => {
		if (!user.tagId || data?.tagById?.status !== TagStatus.Inactive) return false

		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(meData?.me, "tags/activate-working-user-tag")[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(meData?.me, "tags/activate-short-term-user-tag")[0]
		}
	}, [meData, user, data])

	return (
		<Card
			title="Tag"
			action={
				disableActions ? (
					<></>
				) : (
					<ButtonGroup>
						{canAssign && (
							<Button as={NavLink} to={`/users/${user._id}/assignTag`} colorScheme="primary" size="sm">
								Assign
							</Button>
						)}
						{canFlush && (
							<Button as={NavLink} to={`/users/${user._id}/flushTag`} colorScheme="error" size="sm">
								Flush
							</Button>
						)}
						{canDeactivate && (
							<Button as={NavLink} to={`/users/${user._id}/deactivateTag`} colorScheme="error" size="sm">
								Deactivate
							</Button>
						)}
						{canActivate && (
							<Button as={NavLink} to={`/users/${user._id}/activateTag`} colorScheme="success" size="sm">
								Activate
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading tag details</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.tagById ? (
				<Flex justify="center" align="center" flexWrap="wrap">
					<Stat label="UID" value={data.tagById.tagDecimalId} />
					<Stat label="Status" value={data.tagById.status === TagStatus.Active ? <Text color="success.600">Active</Text> : data.tagById.status === TagStatus.Inactive ? <Text color="error.600">Inactive</Text> : "Not available"} />
					<Stat label="Battery Status" value={data.tagById.batteryStatus || "Not available"} />
					<Stat label="Assigned Since" value={data.tagById.assignedAt ? format(new Date(data.tagById.assignedAt), "MMM dd, yyyy p") : "Not available"} />
				</Flex>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any tag.
					</Text>
				</Center>
			)}
		</Card>
	)
}
