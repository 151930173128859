import { Select, Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useMemo } from "react"
import { UserRoleTypes, useUserTypesQuery } from "../../graphql"

export type UserTypeSelectorProps = {
	onUpdate: (userTypeId: string) => void
	value?: string
	roleType?: UserRoleTypes
	defaultValue?: string
}

export const UserTypeSelector: React.FC<UserTypeSelectorProps> = ({ onUpdate, value, roleType, defaultValue }) => {
	const [{ data, error, fetching }] = useUserTypesQuery()

	const userTypes = useMemo(() => {
		if (!roleType) return data?.userTypes || []

		return data?.userTypes.filter((o) => o.roleType === roleType) || []
	}, [data, roleType])

	useEffect(() => {
		if (defaultValue) {
			onUpdate(defaultValue)
		}
	}, [defaultValue])

	return (
		<VStack>
			{fetching ? (
				<Text>Fetching types</Text>
			) : error ? (
				<VStack>
					<Text>Couldn&apos;t fetch types</Text>
					<Text>{error.message.replace("[GraphQL] ", "")}</Text>
				</VStack>
			) : !data?.userTypes.length || !userTypes.length ? (
				<VStack>
					<Text>Couldn&apos;t fetch types</Text>
				</VStack>
			) : (
				<Select flex="1" maxW="24" variant="filled" bgColor="grayscale.input-background" placeholder="Type" defaultValue={defaultValue} value={value} onChange={(e) => onUpdate(e.target.value)}>
					{userTypes.map((type) => (
						<option key={type._id} style={{ backgroundColor: "transparent" }} value={type._id}>
							{type.label.name}
						</option>
					))}
				</Select>
			)}
		</VStack>
	)
}
