import { VStack } from "@chakra-ui/react"
import React from "react"
import { AssignUserRoleForm } from "../../forms"
import { UserFragment } from "../../graphql"

export type AssignUserRoleProps = {
	user: UserFragment
}

export const AssignUserRole: React.FC<AssignUserRoleProps> = ({ user }) => {
	return (
		<VStack w="full" spacing={6}>
			<AssignUserRoleForm user={user} />
		</VStack>
	)
}
