import { VStack } from "@chakra-ui/react"
import React from "react"
import { CheckEmailVerification } from "./components"
import { ContextProvider } from "./context"
import { useMeQuery } from "./graphql"
import { SplashPage } from "./pages/Splash.page"
import { RootRouter } from "./router/Root.router"
import { UnauthorizedRouter } from "./router/Unauthorized.router"

export const App: React.FC = () => {
	const [{ data, fetching }] = useMeQuery()

	if (fetching) {
		return <SplashPage />
	}

	return (
		<ContextProvider>
			<VStack w="full" align="stretch">
				<CheckEmailVerification />
				{data?.me ? (
					<VStack w="full">
						<RootRouter />
					</VStack>
				) : (
					<UnauthorizedRouter />
				)}
			</VStack>
		</ContextProvider>
	)
}
