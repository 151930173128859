import { StackProps, Text, VStack } from "@chakra-ui/react"
import React from "react"

export type StatProps = {
	label: string | number | React.ReactNode
	value: string | number | React.ReactNode
}

export const Stat: React.FC<StatProps & StackProps> = ({ label, value, ...props }) => {
	return (
		<VStack spacing={0} m="4" {...props}>
			{typeof value === "string" || typeof value === "number" ? (
				<Text textAlign="center" fontSize="sm" fontWeight="bold" color="grayscale.body">
					{value}
				</Text>
			) : (
				<VStack w="full" textAlign="center" fontSize="sm" fontWeight="bold" color="grayscale.body">
					{value}
				</VStack>
			)}
			{typeof label === "string" || typeof label === "number" ? (
				<Text textAlign="center" fontSize="xs" color="grayscale.label">
					{label}
				</Text>
			) : (
				<VStack w="full" align="center" textAlign="center" fontSize="xs" color="grayscale.label">
					{label}
				</VStack>
			)}
		</VStack>
	)
}
