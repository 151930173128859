import { Button, ButtonGroup, Center, Flex, HStack, Icon, Link, Spinner, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { CheckCircle, XCircle } from "react-feather"
import { NavLink } from "react-router-dom"
import { useMeQuery, UserFragment, UserRoleTypes, UserStatus, useUserTypeByIdQuery } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card, Stat } from "../common"

export type UserProfileProps = {
	user: UserFragment
}

export const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
	const [{ data }] = useMeQuery()

	const canUpdate = useMemo(() => {
		if (user._id === data?.me?._id) return true

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(data?.me, "users/update-working-user")[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(data?.me, "users/update-short-term-user")[0]
		}

		if (user.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(data?.me, "users/update-admin-user")[0]
		}

		return false
	}, [data, user])

	const canDeactivate = useMemo(() => {
		if (user.status === UserStatus.Inactive) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(data?.me, "users/deactivate-working-user")[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(data?.me, "users/deactivate-short-term-user")[0]
		}

		if (user.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(data?.me, "users/deactivate-admin-user")[0]
		}

		return false
	}, [data, user])

	const canActivate = useMemo(() => {
		if (user.status === UserStatus.Active) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(data?.me, "users/activate-working-user")[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(data?.me, "users/activate-short-term-user")[0]
		}

		if (user.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(data?.me, "users/activate-admin-user")[0]
		}

		return false
	}, [data, user])

	const [typeQuery] = useUserTypeByIdQuery({ variables: { userTypeId: user.typeId }, pause: !user.typeId })

	return (
		<Card
			title="Profile"
			action={
				<ButtonGroup>
					{canUpdate && (
						<Button as={NavLink} to={`/users/${user._id}/updateProfile`} colorScheme="primary" size="sm">
							Update
						</Button>
					)}
					{canDeactivate && (
						<Button as={NavLink} to={`/users/${user._id}/deactivate`} colorScheme="error" size="sm">
							Deactivate
						</Button>
					)}
					{canActivate && (
						<Button as={NavLink} to={`/users/${user._id}/activate`} colorScheme="success" size="sm">
							Activate
						</Button>
					)}
				</ButtonGroup>
			}
		>
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Phone" value={user.phone ? `+${user.phone.countryCode} ${user.phone.number}` : "Not available"} />
				<Stat
					label={
						<HStack>
							<Text>Email</Text>
							{user.email?.isVerified ? (
								<Center rounded="full" bgColor="success.50" p="1">
									<Icon as={CheckCircle} color="success.800" />
								</Center>
							) : data?.me?._id === user._id ? (
								<Button as={NavLink} to="/verifyEmail" variant="link" size="xs" colorScheme="success">
									Verify
								</Button>
							) : (
								<Center rounded="full" bgColor="error.50" p="1">
									<Icon as={XCircle} color="error.800" />
								</Center>
							)}
						</HStack>
					}
					value={user.email?.address || "Not available"}
				/>
				<Stat label="Address" value={user.address || "Not available"} />
				<Stat
					label="Type"
					value={
						typeQuery.fetching ? (
							<Spinner size="sm" />
						) : typeQuery.error ? (
							<Text fontSize="sm" fontWeight="semibold" color="error.500">
								{typeQuery.error.message.replace("[GraphQL] ", "")}
							</Text>
						) : typeQuery.data?.userTypeById ? (
							<Link as={NavLink} to={`/users/types/${user.typeId}`}>
								{typeQuery.data?.userTypeById.label.name}
							</Link>
						) : (
							<Text fontSize="sm" fontWeight="semibold" color="error.500">
								Not available
							</Text>
						)
					}
				/>
				<Stat label="Status" value={user.status === UserStatus.Active ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
				<Stat label="Since" value={format(new Date(user.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
