import { Button, ButtonGroup, Center, List, Text } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { useMeQuery, UserFragment, UserRoleTypes } from "../../graphql"
import { checkForPermissions } from "../../utils"
import { Card } from "../common"
import { UserRoleListItem } from "./UserRoleListItem"

export type UserRoleDetailsProps = {
	user: UserFragment
	disableActions?: boolean
}

export const UserRoleDetails: React.FC<UserRoleDetailsProps> = ({ user, disableActions }) => {
	const [{ data: meData }] = useMeQuery()

	const canAssign = useMemo(() => {
		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(meData?.me, "user-roles/assign-working-role")[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(meData?.me, "user-roles/assign-short-term-role")[0]
		}

		return false
	}, [meData])

	const canExtend = useMemo(() => {
		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (!user.currentRole) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(meData?.me, "user-roles/extend-working-role")[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(meData?.me, "user-roles/extend-short-term-role")[0]
		}

		return false
	}, [meData])

	const canRemove = useMemo(() => {
		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (!user.currentRole) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(meData?.me, "user-roles/remove-working-role")[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(meData?.me, "user-roles/remove-short-term-role")[0]
		}

		return false
	}, [meData])

	return (
		<Card
			title="Current Role"
			action={
				disableActions ? (
					<></>
				) : (
					<ButtonGroup>
						{canAssign && (
							<Button as={NavLink} to={`/users/${user._id}/assignUserRole`} colorScheme="primary" size="sm">
								Assign New
							</Button>
						)}
						{canExtend && (
							<Button as={NavLink} to={`/users/${user._id}/extendUserRole`} colorScheme="primary" size="sm">
								Extend
							</Button>
						)}
						{canRemove && user.currentRole && (
							<Button as={NavLink} to={`/users/${user._id}/removeUserRole`} variant="link" colorScheme="error" size="sm">
								Remove
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			{user.currentRole ? (
				<List>
					<UserRoleListItem userRole={user.currentRole} />
				</List>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						The user has no current role
					</Text>
				</Center>
			)}
		</Card>
	)
}
