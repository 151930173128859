import { Button, ButtonGroup, Text, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useActivateTagOfUserMutation, UserFragment } from "../../graphql"
import { UserTag } from "../common"
import { UserTagDetails } from "./UserTagDetails"

export type ActivateTagOfUserProps = {
	user: UserFragment
}

export const ActivateTagOfUser: React.FC<ActivateTagOfUserProps> = ({ user }) => {
	const [{ fetching }, activate] = useActivateTagOfUserMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleActivate = async () => {
		const { error, data } = await activate({ userId: user._id })

		if (error) {
			toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
			return
		}

		if (data?.activateTagOfUser) {
			toast({ description: "Activated the tag successfully", status: "success" })
			navigate(`/users/${user._id}`)
			return
		} else {
			toast({ description: "Couldn't activate the tag", status: "error" })
			return
		}
	}

	return (
		<VStack w="full" maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={4}>
			<UserTag user={user} />
			<UserTagDetails user={user} disableActions />

			<VStack w="full" align="flex-end" spacing={4}>
				<Text fontSize="sm" color="grayscale.label" alignSelf="flex-start">
					Are you sure you want to activate the tag of {user.name}?
				</Text>
				<ButtonGroup>
					<Button size="sm" as={NavLink} to={`/users/${user._id}`}>
						Cancel
					</Button>
					<Button size="sm" colorScheme="success" isLoading={fetching} onClick={handleActivate}>
						Activate
					</Button>
				</ButtonGroup>
			</VStack>
		</VStack>
	)
}
