/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import DatePicker from "react-date-picker"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ExtendUserRoleMutationVariables, useExtendUserRoleMutation, UserRoleFragment } from "../graphql"

type ExtendUserRoleFormValues = Pick<ExtendUserRoleMutationVariables, "expireAt">

const validationSchema = yup.object<Record<keyof ExtendUserRoleFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	expireAt: yup.date().required().label("Expiry"),
})

export type ExtendUserRoleFormProps = {
	userId: string
	userRole: UserRoleFragment
}

export const ExtendUserRoleForm: React.FC<ExtendUserRoleFormProps> = ({ userId, userRole }) => {
	const initialValues: ExtendUserRoleFormValues = {
		expireAt: userRole.expireAt ? new Date(userRole.expireAt) : new Date(),
	}

	const [{ fetching }, extendUserRole] = useExtendUserRoleMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ expireAt }: ExtendUserRoleFormValues) => {
		const { data, error } = await extendUserRole({ userId, expireAt })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.extendUserRole) {
			navigate(`/users/${userId}`, { replace: true })

			return
		}
	}

	const formik = useFormik<ExtendUserRoleFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.expireAt && formik.errors.expireAt)}>
					<FormLabel fontWeight="bold">End</FormLabel>

					<DatePicker onChange={(date: Date) => formik.setFieldValue("expireAt", date)} value={formik.values.expireAt} format="MMM dd, y" minDate={new Date()} clearIcon={null} />

					<FormErrorMessage>{formik.errors.expireAt as string}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Extend
			</Button>
		</VStack>
	)
}
