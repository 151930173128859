import { Accordion, VStack } from "@chakra-ui/react"
import React from "react"
import { Box, File, FilePlus, Grid, HardDrive, Key, PieChart, Server, Tag, User, Users } from "react-feather"
import { NavLink } from "react-router-dom"
import { Logo } from "../common"
import { AccountPopover } from "../common/AccountPopover"
import { SidebarAccordionItem } from "./SidebarAccordionItem"
import { SidebarButton } from "./SidebarButton"

export const Sidebar: React.FC = () => {
	return (
		<VStack pos="absolute" top="0" left="0" w="320px" h="100vh" bgColor="white !important" align="stretch" justify="space-between" overflow="hidden" zIndex={10} p="4" backgroundColor="grayscale.background">
			<VStack h="full" align="stretch" overflow="auto" pb="4" spacing={8}>
				<NavLink to="/">
					<Logo />
				</NavLink>

				<VStack h="full" align="stretch" overflow="auto" spacing={8}>
					<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
						<SidebarButton to="/" permissionTags={["dashboard/read-disaster-management-dashboard", "dashboard/read-confidentiality-dashboard"]} permissionsType="or" icon={PieChart}>
							Dashboard
						</SidebarButton>

						<SidebarAccordionItem
							tos={["/users", "/users/create", "/users/types", "/users/types/create"]}
							permissionTags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user", "users/create-short-term-user", "users/create-working-user", "users/create-admin-user"]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton isNested isActive={isActive} icon={User}>
									Users
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarButton to="/users" permissionTags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user"]} permissionsType="or">
									Users
								</SidebarButton>
								<SidebarButton to="/users/create" permissionTags={["users/create-short-term-user", "users/create-working-user", "users/create-admin-user"]} permissionsType="or">
									Create User
								</SidebarButton>
								<SidebarAccordionItem
									tos={["/users/types", "/users/types/create"]}
									permissionTags={[
										"user-types/read-short-term-user-type",
										"user-types/read-working-user-type",
										"user-types/read-admin-user-type",
										"user-types/create-short-term-user-type",
										"user-types/create-working-user-type",
										"user-types/create-admin-user-type",
									]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Users}>
											User Types
										</SidebarButton>
									)}
								>
									<SidebarButton to="/users/types" permissionTags={["user-types/read-short-term-user-type", "user-types/read-working-user-type", "user-types/read-admin-user-type"]} permissionsType="or">
										User Types
									</SidebarButton>
									<SidebarButton to="/users/types/create" permissionTags={["user-types/create-short-term-user-type", "user-types/create-working-user-type", "user-types/create-admin-user-type"]} permissionsType="or">
										Create User Type
									</SidebarButton>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>

						<SidebarAccordionItem
							tos={["/reports/past", "/reports/generate"]}
							permissionTags={["reports/read-user-location-records-report"]}
							button={(isActive) => (
								<SidebarButton isNested isActive={isActive} icon={File}>
									Reports
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarButton to="/reports/past">Past</SidebarButton>
								<SidebarAccordionItem
									tos={["/reports/generate/userAttendance"]}
									permissionTags={["reports/create-user-attendance-report"]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={FilePlus}>
											Generate
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/reports/generate/userAttendance" permissionTags={["reports/create-user-attendance-report"]}>
											User Attendance
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>

						<SidebarAccordionItem
							tos={["/departments", "/departments/create", "/permissionsGroups", "/permissionsGroups/create"]}
							permissionTags={[
								"departments/read-department",
								"departments/create-department",
								"permissions/read-permissions-tags",
								"permissions/read-permissions-group",
								"permissions/create-permissions-group",
								"readers/read-reader",
								"readers/create-reader",
								"tags/read-tag",
								"tags/create-tag",
								"services/read-service",
								"services/create-service",
							]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton isNested isActive={isActive} icon={Box}>
									More
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarAccordionItem
									tos={["/departments", "/departments/create"]}
									permissionTags={["departments/read-department", "departments/create-department"]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton icon={Grid} isActive={isActive} isNested>
											Departments
										</SidebarButton>
									)}
								>
									<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
										<SidebarButton to="/departments" permissionTags={["departments/read-department"]}>
											Departments
										</SidebarButton>
										<SidebarButton to="/departments/create" permissionTags={["departments/create-department"]}>
											Create Department
										</SidebarButton>
									</Accordion>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/permissionsGroups", "/permissionsGroups/create"]}
									permissionTags={["permissions/read-permissions-tags", "permissions/read-permissions-group", "permissions/create-permissions-group"]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Key}>
											Permissions Groups
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/permissionsGroups" permissionTags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}>
											Permissions Groups
										</SidebarButton>
										<SidebarButton to="/permissionsGroups/create" permissionTags={["permissions/read-permissions-tags", "permissions/read-permissions-group", "permissions/create-permissions-group"]}>
											Create Permissions Group
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/readers", "/readers/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={HardDrive}>
											Readers
										</SidebarButton>
									)}
									permissionTags={["readers/read-reader", "readers/create-reader"]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/readers" permissionTags={["readers/read-reader"]}>
											Readers
										</SidebarButton>
										<SidebarButton to="/readers/create" permissionTags={["readers/create-reader"]}>
											Create Reader
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/tags", "/tags/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Tag}>
											Tags
										</SidebarButton>
									)}
									permissionTags={["tags/read-tag", "tags/create-tag"]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/tags" permissionTags={["tags/read-tag"]}>
											Tags
										</SidebarButton>
										<SidebarButton to="/tags/create" permissionTags={["tags/create-tag"]}>
											Create Tag
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>

								<SidebarAccordionItem
									tos={["/services", "/services/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Server}>
											Services
										</SidebarButton>
									)}
									permissionTags={["services/read-service", "services/create-service"]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/services" permissionTags={["services/read-service"]}>
											Services
										</SidebarButton>
										<SidebarButton to="/services/create" permissionTags={["services/create-service"]}>
											Create Service
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>
					</Accordion>
				</VStack>
			</VStack>
			<AccountPopover />
		</VStack>
	)
}
