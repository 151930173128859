import { AspectRatio, Box, HStack, Text, VStack } from "@chakra-ui/react"
import React from "react"

export const Logo: React.FC = () => {
	return (
		<VStack w="full" spacing={0}>
			<AspectRatio w="full" maxW="60" ratio={20 / 5}>
				<Box w="full" h="full" backgroundImage="/bigpluto-logo.png" backgroundRepeat="no-repeat" backgroundPosition="center" backgroundSize="contain" />
			</AspectRatio>
			<HStack w="full" justify="center" mt="-10px !important" spacing={1}>
				<Text fontSize="xs" color="grayscale.label">
					powered by
				</Text>
				<AspectRatio w="full" maxW="16" ratio={20 / 5}>
					<Box w="full" h="full" backgroundImage="/logo-192.png" backgroundRepeat="no-repeat" backgroundPosition="center" backgroundSize="contain" />
				</AspectRatio>
			</HStack>
		</VStack>
	)
}
