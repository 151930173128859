import { cacheExchange as cE } from "@urql/exchange-graphcache"
import { betterQueryUpdate } from "../../utils"
import {
	ActivateTagOfUserMutationVariables,
	ActivateUserMutation,
	ActivateUserMutationVariables,
	AllReadersDocument,
	AllReadersQuery,
	AllReadersQueryVariables,
	AssignTagMutation,
	AssignTagMutationVariables,
	AssignUserRoleMutation,
	AssignUserRoleMutationVariables,
	CreateReaderMutation,
	DeactivateTagOfUserMutationVariables,
	DeactivateUserMutation,
	DeactivateUserMutationVariables,
	DepartmentsByKeywordQueryVariables,
	ExtendUserRoleMutation,
	ExtendUserRoleMutationVariables,
	FinishAvatarUploadMutation,
	FinishAvatarUploadMutationVariables,
	FlushTagMutationVariables,
	LoginMutation,
	LogoutMutation,
	MeDocument,
	MeQuery,
	PermissionsGroupsByKeywordQueryVariables,
	Reader,
	ReaderFragment,
	ReaderFragmentDoc,
	ReadersByFilterQueryVariables,
	ReaderUpdateSubscription,
	ReaderUpdateSubscriptionVariables,
	RemoveCurrentUserRoleMutation,
	RemoveCurrentUserRoleMutationVariables,
	Tag,
	TagFragment,
	TagFragmentDoc,
	TagsByFilterQueryVariables,
	TagStatus,
	UpdateReaderMutation,
	UpdateReaderMutationVariables,
	UpdateUserMutation,
	UpdateUserMutationVariables,
	UpdateUserPermissionsGroupsMutation,
	UpdateUserPermissionsGroupsMutationVariables,
	User,
	UserByIdDocument,
	UserByIdQuery,
	UserByIdQueryVariables,
	UserFragment,
	UserFragmentDoc,
	UserPastAttendanceRecordsQueryVariables,
	UserPermissionsGroupsDocument,
	UserPermissionsGroupsQuery,
	UserPermissionsGroupsQueryVariables,
	UserRoleStatus,
	UsersByDepartmentQueryVariables,
	UsersByKeywordQueryVariables,
	UsersByTypeQueryVariables,
	UsersQueryVariables,
	UserTypesByKeywordQueryVariables,
	UserWithPermissionsGroups,
	UserWithPermissionsGroupsFragment,
	UserWithPermissionsGroupsFragmentDoc,
	VerifyEmailWithSecretCodeMutation,
} from "../generated"
import schema from "../generated/schema.json"
import { customPagination } from "../pagination"

export const cacheExchange = cE({
	schema: schema as any,
	keys: {
		Label: () => null,
		Phone: () => null,
		Email: () => null,
		Picture: () => null,
		PictureObject: () => null,
		Position: () => null,
		PaginatedUsersResponse: () => null,
		Tag: (data: any) => data._id,
		PaginatedUserTypesResponse: () => null,
		PaginatedZonesResponse: () => null,
		PaginatedZoneGroupsResponse: () => null,
		PaginatedAlertTargetGroupsResponse: () => null,
		PaginatedReportsResponse: () => null,
		PaginatedPermissionsGroupsResponse: () => null,
		PaginatedUserAttendanceRecordsResponse: () => null,
		PaginatedDepartmentsResponse: () => null,
		PaginatedReadersResponse: () => null,
		PaginatedTagsResponse: () => null,
		PaginatedAlertFlowsResponse: () => null,
		PaginatedAlertsResponse: () => null,
		ZoneWiseUsersResponse: () => null,
		TypeWiseUsersResponse: () => null,
		ReportObject: () => null,
		TrackedUsersResponse: () => null,
		PaginatedUserLocationRecordsWithZoneResponse: () => null,
	},
	resolvers: {
		Query: {
			allUsers: customPagination("users", "PaginatedUsersResponse"),
			usersByKeyword: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: UsersByKeywordQueryVariables, vars: UsersByKeywordQueryVariables) =>
					args.keyword === vars.keyword &&
					args.typeId === vars.typeId &&
					args.roleType === vars.roleType &&
					args.status === vars.status &&
					(args.since && vars.since ? new Date(args.since).getTime() === new Date(vars.since).getTime() : true) &&
					(args.until && vars.until ? new Date(args.until).getTime() === new Date(vars.until).getTime() : true)
			),
			usersByType: customPagination("users", "PaginatedUsersResponse", (args: UsersByTypeQueryVariables, vars: UsersByTypeQueryVariables) => args.typeId === vars.typeId),
			usersByDepartment: customPagination("users", "PaginatedUsersResponse", (args: UsersByDepartmentQueryVariables, vars: UsersByDepartmentQueryVariables) => args.departmentId === vars.departmentId),
			users: customPagination(
				"users",
				"PaginatedUsersResponse",
				(args: UsersQueryVariables, vars: UsersQueryVariables) =>
					args.filter.departmentId === vars.filter.departmentId && args.filter.keyword === vars.filter.keyword && args.filter.roleType === vars.filter.roleType && args.filter.userTypeId === vars.filter.userTypeId
			),

			allUserTypes: customPagination("userTypes", "PaginatedUserTypesResponse"),
			userTypesByKeyword: customPagination("userTypes", "PaginatedUserTypesResponse", (args: UserTypesByKeywordQueryVariables, vars: UserTypesByKeywordQueryVariables) => args.keyword === vars.keyword),

			allPermissionsGroups: customPagination("permissionsGroups", "PaginatedPermissionsGroupsResponse"),
			permissionsGroupsByKeyword: customPagination(
				"permissionsGroups",
				"PaginatedPermissionsGroupsResponse",
				(args: PermissionsGroupsByKeywordQueryVariables, vars: PermissionsGroupsByKeywordQueryVariables) => args.keyword === vars.keyword
			),

			userPastAttendanceRecords: customPagination(
				"userAttendanceRecords",
				"PaginatedUserAttendanceRecordsResponse",
				(args: UserPastAttendanceRecordsQueryVariables, vars: UserPastAttendanceRecordsQueryVariables) => args.userId === vars.userId && new Date(args.date).getTime() === new Date(vars.date).getTime()
			),
			allDepartments: customPagination("departments", "PaginatedDepartmentsResponse"),
			departmentsByKeyword: customPagination("departments", "PaginatedDepartmentsResponse", (args: DepartmentsByKeywordQueryVariables, vars: DepartmentsByKeywordQueryVariables) => args.keyword === vars.keyword),
			allReaders: customPagination("readers", "PaginatedReadersResponse"),
			readersByFilter: customPagination(
				"readers",
				"PaginatedReadersResponse",
				(args: ReadersByFilterQueryVariables, vars: ReadersByFilterQueryVariables) => args.filter.keyword === vars.filter.keyword && args.filter.type === vars.filter.type && args.filter.isActive === vars.filter.isActive
			),
			allTags: customPagination("tags", "PaginatedTagsResponse"),
			tagsByFilter: customPagination(
				"tags",
				"PaginatedTagsResponse",
				(args: TagsByFilterQueryVariables, vars: TagsByFilterQueryVariables) => args.filter.keyword === vars.filter.keyword && args.filter.status === vars.filter.status && args.filter.batteryStatus === vars.filter.batteryStatus
			),
		},
	},
	updates: {
		Subscription: {
			readerUpdate: (result: ReaderUpdateSubscription, variables: ReaderUpdateSubscriptionVariables, cache) => {
				const reader = cache.readFragment<ReaderFragment>(ReaderFragmentDoc, { _id: variables.readerId } as Reader)

				if (reader) cache.writeFragment<ReaderFragment, { _id: string }>(ReaderFragmentDoc, { ...reader, ...result.readerUpdate }, { _id: variables.readerId })
			},
		},
		Mutation: {
			login: (_result, _, cache) => {
				betterQueryUpdate<LoginMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.login.user) {
						return {
							me: result.login.user,
						}
					}

					return query
				})
			},
			logout: (result, _, cache) => {
				betterQueryUpdate<LogoutMutation, MeQuery>(cache, { query: MeDocument }, result, () => ({ me: null }))
			},
			verifyEmailWithSecretCode: (_result, _, cache) => {
				betterQueryUpdate<VerifyEmailWithSecretCodeMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.verifyEmailWithSecretCode && query?.me) {
						return {
							...query,
							me: { ...query.me, email: { ...query.me.email!, isVerified: true, verifiedAt: new Date() } },
						}
					}

					return query
				})
			},
			updateUser: (result: UpdateUserMutation, variables: UpdateUserMutationVariables, cache) => {
				cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...result.updateUser, __typename: "User" }, { _id: variables.userId })
			},
			activateUser: (result: ActivateUserMutation, variables: ActivateUserMutationVariables, cache) => {
				cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...result.activateUser, __typename: "User" }, { _id: variables.userId })
			},
			deactivateUser: (result: DeactivateUserMutation, variables: DeactivateUserMutationVariables, cache) => {
				cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...result.deactivateUser, __typename: "User" }, { _id: variables.userId })
			},
			updateUserPermissionsGroups: (result: UpdateUserPermissionsGroupsMutation, variables: UpdateUserPermissionsGroupsMutationVariables, cache) => {
				betterQueryUpdate<UpdateUserPermissionsGroupsMutation, UserPermissionsGroupsQuery>(
					cache,
					{ query: UserPermissionsGroupsDocument, variables: { userId: variables.userId } as UserPermissionsGroupsQueryVariables },
					result,
					(_result, query) => {
						if (_result) {
							return {
								...query,
								userPermissionsGroups: result.updateUserPermissionsGroups,
							}
						}

						return query
					}
				)
			},
			assignUserRole: (result: AssignUserRoleMutation, variables: AssignUserRoleMutationVariables, cache) => {
				betterQueryUpdate<AssignUserRoleMutation, UserByIdQuery>(cache, { query: UserByIdDocument, variables: { userId: variables.userId } as UserByIdQueryVariables }, result, (_result, query) => {
					if (_result && query?.userById) {
						const pastRoles = [...(query.userById.pastRoles || [])]

						if (query.userById.currentRole) {
							pastRoles.unshift({ ...query.userById.currentRole, removedAt: new Date(), status: UserRoleStatus.Past })
						}

						return {
							...query,
							userById: {
								...query.userById,
								currentRole: result.assignUserRole,
								pastRoles,
							},
						}
					}

					return query
				})
			},
			extendUserRole: (result: ExtendUserRoleMutation, variables: ExtendUserRoleMutationVariables, cache) => {
				betterQueryUpdate<ExtendUserRoleMutation, UserByIdQuery>(cache, { query: UserByIdDocument, variables: { userId: variables.userId } as UserByIdQueryVariables }, result, (_result, query) => {
					if (_result && query?.userById) {
						return {
							...query,
							userById: {
								...query.userById,
								currentRole: result.extendUserRole,
							},
						}
					}

					return query
				})
			},
			removeCurrentUserRole: (result: RemoveCurrentUserRoleMutation, variables: RemoveCurrentUserRoleMutationVariables, cache) => {
				betterQueryUpdate<RemoveCurrentUserRoleMutation, UserByIdQuery>(cache, { query: UserByIdDocument, variables: { userId: variables.userId } as UserByIdQueryVariables }, result, (_result, query) => {
					if (_result && query?.userById) {
						const pastRoles = [...(query.userById.pastRoles || [])]

						if (query.userById.currentRole) {
							pastRoles.unshift({ ...query.userById.currentRole, removedAt: new Date(), status: UserRoleStatus.Past })
						}

						return {
							...query,
							userById: {
								...query.userById,
								currentRole: null,
								pastRoles,
							},
						}
					}

					return query
				})
			},
			activateTagOfUser: (_, variables: ActivateTagOfUserMutationVariables, cache) => {
				const tag = cache.readFragment<TagFragment, { userId: string }>(TagFragmentDoc, { assignedToUserId: variables.userId } as Tag)

				if (tag) {
					cache.writeFragment<TagFragment>(TagFragmentDoc, {
						...tag,
						status: TagStatus.Active,
						__typename: "Tag",
					})
				}
			},
			deactivateTagOfUser: (_, variables: DeactivateTagOfUserMutationVariables, cache) => {
				const tag = cache.readFragment<TagFragment>(TagFragmentDoc, { assignedToUserId: variables.userId } as Tag)

				if (tag) {
					cache.writeFragment<TagFragment>(TagFragmentDoc, {
						...tag,
						status: TagStatus.Inactive,
						__typename: "Tag",
					})
				}
			},
			flushTag: (_, variables: FlushTagMutationVariables, cache) => {
				const user = cache.readFragment<UserFragment>(UserFragmentDoc, { _id: variables.userId } as User)

				if (!user) return

				cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...user, tagId: null }, { _id: variables.userId })
			},
			assignTag: (result: AssignTagMutation, variables: AssignTagMutationVariables, cache) => {
				const user = cache.readFragment<UserFragment>(UserFragmentDoc, { _id: variables.userId } as User)

				if (!user) return

				cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...user, tagId: result.assignTag._id }, { _id: variables.userId })
			},
			createReader: (result: CreateReaderMutation, _, cache) => {
				betterQueryUpdate<CreateReaderMutation, AllReadersQuery>(cache, { query: AllReadersDocument, variables: { pagination: { limit: 20, page: 1 } } as AllReadersQueryVariables }, result, (_result, query) => {
					if (_result && query) {
						return {
							...query,
							allReaders: { ...query.allReaders, readers: [result.createReader, ...query.allReaders.readers] },
						}
					}

					return query
				})
			},
			updateReader: (result: UpdateReaderMutation, variables: UpdateReaderMutationVariables, cache) => {
				cache.writeFragment<ReaderFragment, { _id: string }>(ReaderFragmentDoc, result.updateReader, { _id: variables.readerId })
			},
			finishAvatarUpload: (result: FinishAvatarUploadMutation, variables: FinishAvatarUploadMutationVariables, cache) => {
				const user = cache.readFragment<UserFragment>(UserFragmentDoc, { _id: variables.userId } as User)

				if (user) cache.writeFragment<UserFragment, { _id: string }>(UserFragmentDoc, { ...user, picture: result.finishAvatarUpload }, { _id: variables.userId })

				const meUser = cache.readFragment<UserWithPermissionsGroupsFragment>(UserWithPermissionsGroupsFragmentDoc, { _id: variables.userId } as UserWithPermissionsGroups)

				if (meUser) cache.writeFragment<UserWithPermissionsGroupsFragment, { _id: string }>(UserWithPermissionsGroupsFragmentDoc, { ...meUser, picture: result.finishAvatarUpload }, { _id: variables.userId })
			},
		},
	},
})
