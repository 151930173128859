import { Select, VStack } from "@chakra-ui/react"
import { startCase } from "lodash"
import React, { useEffect, useMemo } from "react"
import { useMeQuery, UserRoleTypes } from "../../graphql"
import { checkForPermissions } from "../../utils"

export type UserRoleTypeSelectorProps = {
	onUpdate: (type: UserRoleTypes) => void
	value?: UserRoleTypes
	defaultValue?: UserRoleTypes
}

export const UserRoleTypeSelector: React.FC<UserRoleTypeSelectorProps> = ({ onUpdate, value, defaultValue }) => {
	const [meQuery] = useMeQuery()

	const userRoleTypes = useMemo(() => {
		const _ = []

		if (checkForPermissions(meQuery.data?.me, "users/read-short-term-user")[0]) {
			_.push(UserRoleTypes.ShortTerm)
		}

		if (checkForPermissions(meQuery.data?.me, "users/read-working-user")[0]) {
			_.push(UserRoleTypes.Working)
		}

		if (checkForPermissions(meQuery.data?.me, "users/read-admin-user")[0]) {
			_.push(UserRoleTypes.Admin)
		}

		return _
	}, [meQuery.data?.me])

	useEffect(() => {
		if (defaultValue) {
			onUpdate(defaultValue)
		}
	}, [defaultValue])

	return (
		<VStack>
			<Select flex="1" maxW="24" variant="filled" bgColor="grayscale.input-background" placeholder="Role Type" defaultValue={defaultValue} value={value} onChange={(e) => onUpdate(e.target.value as UserRoleTypes)}>
				{userRoleTypes.map((type) => (
					<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
						{startCase(type)}
					</option>
				))}
			</Select>
		</VStack>
	)
}
