import { VStack } from "@chakra-ui/react"
import React from "react"
import { UpdateUserProfileForm } from "../../forms"
import { UserFragment } from "../../graphql"

export type UpdateUserProfileProps = {
	user: UserFragment
}

export const UpdateUserProfile: React.FC<UpdateUserProfileProps> = ({ user }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<UpdateUserProfileForm user={user} />
		</VStack>
	)
}
