/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Text, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent, useMemo } from "react"
import DatePicker from "react-date-picker"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { UserSelector } from "../components"
import { AssignUserRoleMutationVariables, useAssignUserRoleMutation, useDepartmentsQuery, UserFragment, UserRoleTypes } from "../graphql"

type AssignUserRoleFormValues = AssignUserRoleMutationVariables["input"]

export type AssignUserRoleFormProps = {
	user: UserFragment
}

export const AssignUserRoleForm: React.FC<AssignUserRoleFormProps> = ({ user }) => {
	const validationSchema = useMemo(
		() =>
			yup.object<Record<keyof AssignUserRoleFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
				designation: yup.string().nullable().label("Designation"),
				expireAt: yup.date().nullable().label("Expiry"),
				departmentId: yup.string().required().label("Deparment"),
				assignedUserIds: user.roleType === UserRoleTypes.ShortTerm ? yup.array().of(yup.string()).required().label("Assigned Users") : yup.array().of(yup.string()).nullable().label("Assigned Users"),
			}),
		[user]
	)

	const initialValues: AssignUserRoleFormValues = {
		designation: "",
		expireAt: null,
		departmentId: "",
		assignedUserIds: [],
	}

	const [{ fetching }, assignUserRole] = useAssignUserRoleMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: AssignUserRoleFormValues) => {
		const { data, error } = await assignUserRole({ userId: user._id, input: { ...values, assignedUserIds: user.roleType === UserRoleTypes.ShortTerm ? values.assignedUserIds : undefined } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.assignUserRole) {
			navigate(`/users/${user._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<AssignUserRoleFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: departmentsData, error: departmentsError, fetching: departmentsFetching }] = useDepartmentsQuery()

	console.log(formik.errors)

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" maxW="sm" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.designation && formik.errors.designation)}>
					<FormLabel fontWeight="bold">Designation (Optional)</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter designation" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("designation")} />

					<FormErrorMessage>{formik.errors.designation}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.departmentId && formik.errors.departmentId)}>
					<FormLabel fontWeight="bold">Department</FormLabel>

					{departmentsFetching ? (
						<Text>Fetching departments</Text>
					) : departmentsError ? (
						<VStack>
							<Text>Couldn&apos;t fetch departments</Text>
							<Text>{departmentsError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !departmentsData?.departments.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch departments</Text>
						</VStack>
					) : (
						<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select department" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("departmentId")}>
							{departmentsData.departments.map((department) => (
								<option key={department._id} style={{ backgroundColor: "transparent" }} value={department._id}>
									{department.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.departmentId}</FormErrorMessage>
				</FormControl>

				{user.roleType === UserRoleTypes.ShortTerm && formik.values.departmentId ? (
					<FormControl isInvalid={Boolean(formik.touched.assignedUserIds && formik.errors.assignedUserIds)}>
						<FormLabel fontWeight="bold">Assigned users</FormLabel>

						<UserSelector
							value={formik.values.assignedUserIds || []}
							onUpdate={(userIds) => {
								formik.setFieldValue("assignedUserIds", userIds)
							}}
							roleType={UserRoleTypes.Working}
							departmentId={formik.values.departmentId}
						/>

						<FormErrorMessage>{formik.errors.assignedUserIds as string}</FormErrorMessage>
					</FormControl>
				) : (
					<></>
				)}

				<FormControl isInvalid={Boolean(formik.touched.expireAt && formik.errors.expireAt)}>
					<FormLabel fontWeight="bold">Expiry</FormLabel>

					<DatePicker onChange={(date: Date) => formik.setFieldValue("expireAt", date)} value={formik.values.expireAt} format="MMM dd, y" minDate={new Date()} clearIcon={null} />

					<FormErrorMessage>{formik.errors.expireAt as string}</FormErrorMessage>
				</FormControl>
				<Button type="submit" colorScheme="primary" isLoading={fetching}>
					Assign
				</Button>
			</VStack>
		</VStack>
	)
}
