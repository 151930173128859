import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { UserFragment } from "../../graphql"
import { UserListItem } from "./UserListItem"

export type UsersListProps = {
	users: UserFragment[]
	loadMore?: () => void
	onUserClick?: (user: UserFragment) => void
}

export const UsersList: React.FC<UsersListProps> = ({ users, loadMore, onUserClick }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore?.()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{users.map((user) => (
				<>
					<UserListItem key={user._id} user={user} onUserClick={onUserClick} />
					<Divider />
				</>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
