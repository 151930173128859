import { Center, chakra, Heading, HStack, Spinner, Text, VStack, Link } from "@chakra-ui/react"
import React from "react"
import { useTotalUsersQuery, useUserTypesQuery } from "../../graphql"
import { NavLink } from "react-router-dom"

export const TotalUsers: React.FC = () => {
	const [{ data, fetching, error }] = useTotalUsersQuery()

	const [typesQuery] = useUserTypesQuery()


	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">Total users</Heading>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.totalUsers ? (
				<VStack align="stretch">
					<HStack>
						<VStack align="stretch">
							<Link as={NavLink} to={"/users"}>
								<Heading color="primary.400">{data.totalUsers.flatMap((o) => o.userIds).length || 0}</Heading>
							</Link>
						</VStack>
					</HStack>
					{typesQuery.fetching ? (
						<Spinner size="sm" />
					) : typesQuery.data ? (
						<Text fontSize="sm" color="grayscale.label">
							{typesQuery.data?.userTypes.map((type, i, _) => (
								<Link key={type._id} as={NavLink} to={`/users?userType=${type._id}`}>
									<chakra.span key={type._id} fontWeight="semibold">
										{data.totalUsers.find((o) => o.typeId === type._id)?.userIds.length || 0} <chakra.span fontWeight="normal">{type.label.name}</chakra.span>{" "}
										{_.length - 1 !== i && <chakra.span fontWeight="semibold">• </chakra.span>}
									</chakra.span>
								</Link>
							))}
						</Text>
					) : (
						<></>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
