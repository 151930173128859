import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useCallback, useState } from "react"
import DatePicker from "react-date-picker"
import { StatusLogsOfReaderQueryVariables, useStatusLogsOfReaderQuery } from "../../graphql"
import { Card } from "../common"
import { ReaderStatusLogsList } from "./ReaderStatusLogsList"

export type ReaderStatusLogsProps = {
	readerId: string
}

export const ReaderStatusLogs: React.FC<ReaderStatusLogsProps> = ({ readerId }) => {
	const [date, setDate] = useState(new Date(format(new Date(), "MM/dd/yyyy")))
	const [pagination, setPagination] = useState<StatusLogsOfReaderQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useStatusLogsOfReaderQuery({ variables: { readerId, pagination, date } })

	const nextPage = useCallback(() => {
		if (data?.statusLogsOfReader.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data])

	return (
		<Card title="Status Logs" alwaysShowAction action={<DatePicker onChange={setDate} value={date} format="MMM dd, y" maxDate={new Date()} clearIcon={null} />}>
			{fetching && !data?.statusLogsOfReader ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading logs</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.statusLogsOfReader.readerStatusLogs.length ? (
				<VStack w="full" align="stretch">
					<ReaderStatusLogsList logs={data.statusLogsOfReader.readerStatusLogs} loadMore={nextPage} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more logs</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find logs
					</Text>
				</Center>
			)}
		</Card>
	)
}
