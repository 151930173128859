import { Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { DepartmentFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type DepartmentDetailsProps = {
	department: DepartmentFragment
}

export const DepartmentDetails: React.FC<DepartmentDetailsProps> = ({ department }) => {
	return (
		<Card title="Details">
			<Flex justify="center" align="center" flexWrap="wrap">
				<Stat label="Created" value={format(new Date(department.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
