import { Center, chakra, Heading, HStack, Spinner, Text, VStack, Link } from "@chakra-ui/react"
import React from "react"
import { useLateUsersQuery, usePresentUsersQuery, useUserTypesQuery } from "../../graphql"
import { LiveIndicator } from "../common"
import { NavLink } from "react-router-dom"

export const PresentUsers: React.FC = () => {
	const [{ data, fetching, error }] = usePresentUsersQuery()

	const [lateUsersQuery] = useLateUsersQuery()

	const [typesQuery] = useUserTypesQuery()



	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<HStack w="full" spacing={6}>
				<Heading fontSize="sm">Present users</Heading>

				<LiveIndicator />
			</HStack>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.presentUsers ? (
				<VStack align="stretch">
					<Link as={NavLink} to={"/users/active"}>
						<Heading color="primary.400">{data.presentUsers.flatMap((o) => o.userIds).length || 0}</Heading>
					</Link>
					{lateUsersQuery.data?.lateUsers.flatMap((o) => o.userIds).length && (
						<Text fontSize="sm" color="grayscale.label">
							<Link as={NavLink} to={"/users/late"}>
								<chakra.span fontWeight="semibold">{lateUsersQuery.data?.lateUsers.flatMap((o) => o.userIds).length}</chakra.span> late users
							</Link>
						</Text>
					)}

					{typesQuery.fetching ? (

						<Spinner size="sm" />
					) : typesQuery.data ? (
						<Text fontSize="sm" color="grayscale.label">
							{typesQuery.data?.userTypes.map((type, i, _) => (
								<Link key={type._id} as={NavLink} to={`/users/active?userType=${type._id}`}>
									<chakra.span key={type._id} fontWeight="semibold">
										{data.presentUsers.find((o) => o.typeId === type._id)?.userIds.length || 0} <chakra.span fontWeight="normal">{type.label.name}</chakra.span>{" "}
										{_.length - 1 !== i && <chakra.span fontWeight="semibold">• </chakra.span>}
									</chakra.span>
								</Link>
							))}
						</Text>
					) : (
						<></>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}


