import { Flex, HStack, Link, ListItem, Spinner, Text } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"
import { useDepartmentByIdQuery, UserRoleFragment, UserRoleStatus } from "../../graphql"
import { Stat } from "../common"

export type UserRoleListItemProps = {
	userRole: UserRoleFragment
}

export const UserRoleListItem: React.FC<UserRoleListItemProps> = ({ userRole }) => {
	const [departmentQuery] = useDepartmentByIdQuery({ variables: { departmentId: userRole.departmentId } })

	return (
		<ListItem _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<HStack justifyContent="space-between" p="2">
				<Flex justify="center" align="center" flexWrap="wrap">
					<Stat
						label="Department"
						value={
							departmentQuery.fetching ? (
								<Spinner size="sm" />
							) : departmentQuery.error ? (
								<Text fontSize="sm" fontWeight="semibold" color="error.500">
									{departmentQuery.error.message.replace("[GraphQL] ", "")}
								</Text>
							) : departmentQuery.data?.departmentById ? (
								<Link as={NavLink} to={`/departments/${departmentQuery.data.departmentById._id}`}>
									{departmentQuery.data?.departmentById.label.name}
								</Link>
							) : (
								<Text fontSize="sm" fontWeight="semibold" color="error.500">
									Not available
								</Text>
							)
						}
					/>
					<Stat label="Designation" value={userRole.designation || "Not available"} />
					<Stat label="Expiry" value={userRole.expireAt ? format(new Date(userRole.expireAt), "MMM dd, yyyy p") : "Not available"} />
					<Stat label="Assigned Since" value={format(new Date(userRole!.assignedAt), "MMM dd, yyyy p")} />
					<Stat
						label="Status"
						value={
							userRole.status === UserRoleStatus.Active ? <Text color="success.600">Active</Text> : userRole.status === UserRoleStatus.Expired ? <Text color="error.600">Expired</Text> : <Text color="warning.600">Past</Text>
						}
					/>
				</Flex>
			</HStack>
		</ListItem>
	)
}
