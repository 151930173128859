import loadable from "@loadable/component"
import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { Permissions } from "../components"
import { Scaffold } from "../components/common/Scaffold"
import { useMeQuery } from "../graphql"
import { checkForSomePermissions } from "../utils"


const DashboardPage = loadable(() => import("../pages/dashboard/Dashboard.page"))

const UsersPage = loadable(() => import("../pages/users/Users.page"))
const CreateUserPage = loadable(() => import("../pages/users/CreateUser.page"))
const UpdateUserProfilePage = loadable(() => import("../pages/users/UpdateUserProfile.page"))
const UpdateUserPermissionsGroupsPage = loadable(() => import("../pages/users/UpdateUserPermissionsGroups.page"))
const ExtendUserRolePage = loadable(() => import("../pages/users/ExtendUserRole.page"))
const AssignUserRolePage = loadable(() => import("../pages/users/AssignUserRole.page"))
const RemoveCurrentUserRolePage = loadable(() => import("../pages/users/RemoveCurrentUserRole.page"))
const ActivateUserPage = loadable(() => import("../pages/users/ActivateUser.page"))
const DeactivateUserPage = loadable(() => import("../pages/users/DeactivateUser.page"))
const UserPageWrapper = loadable(() => import("../components/auth/UserPageWrapper"))
const ProfilePage = loadable(() => import("../pages/Profile.page"))
const ActiveUsersPage = loadable(() => import("../pages/users/ActiveUsers.page"))
const LateUsersPage = loadable(() => import("../pages/users/LateUsers.page"))

const AssignTagPage = loadable(() => import("../pages/users/AssignTag.page"))
const FlushTagPage = loadable(() => import("../pages/users/FlushTag.page"))
const ActivateTagOfUserPage = loadable(() => import("../pages/users/ActivateTagOfUser.page"))
const DeactivateTagOfUserPage = loadable(() => import("../pages/users/DeactivateTagOfUser.page"))


const UserTypesPage = loadable(() => import("../pages/userTypes/UserTypes.page"))
const UserTypePage = loadable(() => import("../pages/userTypes/UserType.page"))
const CreateUserTypePage = loadable(() => import("../pages/userTypes/CreateUserType.page"))

const PermissionsGroupsPage = loadable(() => import("../pages/permissionsGroups/PermissionsGroups.page"))
const PermissionsGroupPage = loadable(() => import("../pages/permissionsGroups/PermissionsGroup.page"))
const CreatePermissionsGroupPage = loadable(() => import("../pages/permissionsGroups/CreatePermissionsGroup.page"))

const GenerateUserAttendanceReportPage = loadable(() => import("../pages/reports/generate/GenerateUserAttendanceReport.page"))

const ReportPage = loadable(() => import("../pages/reports/past/Report"))
const ReportsPage = loadable(() => import("../pages/reports/past/Reports"))

const ChangePassword = loadable(() => import("../pages/ChangePassword.page"))
const VerifyEmail = loadable(() => import("../pages/VerifyEmail.page"))

const DepartmentsPage = loadable(() => import("../pages/departments/Departments.page"))
const DepartmentPage = loadable(() => import("../pages/departments/Department.page"))
const CreateDepartmentPage = loadable(() => import("../pages/departments/CreateDepartment.page"))

const ReadersPage = loadable(() => import("../pages/readers/Readers.page"))
const ReaderPage = loadable(() => import("../pages/readers/Reader.page"))
const CreateReaderPage = loadable(() => import("../pages/readers/CreateReader.page"))
const UpdateReaderPage = loadable(() => import("../pages/readers/UpdateReader.page"))

const TagsPage = loadable(() => import("../pages/tags/Tags.page"))
const TagPage = loadable(() => import("../pages/tags/Tag.page"))
const CreateTagPage = loadable(() => import("../pages/tags/CreateTag.page"))

const ServicesPage = loadable(() => import("../pages/services/Services.page"))
const ServicePage = loadable(() => import("../pages/services/Service.page"))
const CreateServicePage = loadable(() => import("../pages/services/CreateService.page"))

const NotFoundPage = loadable(() => import("../pages/NotFound.page"))
const UnauthorizedPage = loadable(() => import("../pages/Unauthorized.page"))

export const RootRouter: React.FC = () => {
	const [{ data }] = useMeQuery()

	return (
		<Routes>
			<Route path="/" element={<Scaffold />}>
				<Route
					path="/"
					element={
						checkForSomePermissions(data?.me, "dashboard/read-disaster-management-dashboard", "dashboard/read-confidentiality-dashboard")[0] ? (
							<Permissions tags={["dashboard/read-disaster-management-dashboard", "dashboard/read-confidentiality-dashboard"]} checkType="or">
								<DashboardPage />
							</Permissions>
						) : (
							<Navigate to="/users" />
						)
					}
				/>
				<Route
					path="/users"
					element={
						<Permissions tags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user"]} checkType="or">
							<UsersPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/create"
					element={
						<Permissions tags={["users/create-short-term-user", "users/create-working-user", "users/create-admin-user"]} checkType="or">
							<CreateUserPage />
						</Permissions>
					}
				/>
				<Route path="/users/:userId" element={<UserPageWrapper />} />
				<Route
					path="/users/:userId/updateProfile"
					element={
						<Permissions tags={["users/update-short-term-user", "users/update-working-user", "users/update-admin-user"]} checkType="or">
							<UpdateUserProfilePage />
						</Permissions>
					}
				/>
				<Route
					path="/users/:userId/deactivate"
					element={
						<Permissions tags={["users/deactivate-short-term-user", "users/deactivate-working-user", "users/deactivate-admin-user"]} checkType="or">
							<DeactivateUserPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/:userId/activate"
					element={
						<Permissions tags={["users/activate-short-term-user", "users/activate-working-user", "users/activate-admin-user"]} checkType="or">
							<ActivateUserPage />
						</Permissions>
					}
				/>
				<Route path="/users/:userId/deactivateTag" element={<DeactivateTagOfUserPage />} />
				<Route path="/users/:userId/activateTag" element={<ActivateTagOfUserPage />} />
				<Route path="/users/:userId/assignTag" element={<AssignTagPage />} />
				<Route path="/users/:userId/flushTag" element={<FlushTagPage />} />
				<Route
					path="/users/:userId/updatePermissionsGroups"
					element={
						<Permissions tags={["permissions/update-short-term-user-permissions-group", "permissions/update-working-user-permissions-group", "permissions/update-admin-user-permissions-group"]} checkType="or">
							<UpdateUserPermissionsGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/:userId/extendUserRole"
					element={
						<Permissions tags={["user-roles/extend-short-term-role", "user-roles/extend-working-role"]} checkType="or">
							<ExtendUserRolePage />
						</Permissions>
					}
				/>
				<Route
					path="/users/:userId/assignUserRole"
					element={
						<Permissions tags={["user-roles/assign-short-term-role", "user-roles/assign-working-role"]} checkType="or">
							<AssignUserRolePage />
						</Permissions>
					}
				/>
				<Route
					path="/users/:userId/removeUserRole"
					element={
						<Permissions tags={["user-roles/remove-short-term-role", "user-roles/remove-working-role"]} checkType="or">
							<RemoveCurrentUserRolePage />
						</Permissions>
					}
				/>
				<Route
					path="/users/types"
					element={
						<Permissions tags={["user-types/read-short-term-user-type", "user-types/read-working-user-type", "user-types/read-admin-user-type"]} checkType="or">
							<UserTypesPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/active"
					element={
						<Permissions tags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user"]} checkType="or">
							<ActiveUsersPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/late"
					element={
						<Permissions tags={["users/read-short-term-user", "users/read-working-user", "users/read-admin-user"]} checkType="or">
							<LateUsersPage />
						</Permissions>
					}
				/>
				<Route
					path="/users/types/create"
					element={
						<Permissions tags={["user-types/create-short-term-user-type", "user-types/create-working-user-type", "user-types/create-admin-user-type"]} checkType="or">
							<CreateUserTypePage />
						</Permissions>
					}
				/>
				<Route
					path="/users/types/:userTypeId"
					element={
						<Permissions tags={["user-types/read-short-term-user-type", "user-types/read-working-user-type", "user-types/read-admin-user-type"]} checkType="or">
							<UserTypePage />
						</Permissions>
					}
				/>

				<Route path="/profile" element={<ProfilePage />} />
				<Route
					path="/permissionsGroups"
					element={
						<Permissions tags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}>
							<PermissionsGroupsPage />
						</Permissions>
					}
				/>
				<Route
					path="/permissionsGroups/create"
					element={
						<Permissions tags={["permissions/read-permissions-tags", "permissions/read-permissions-group", "permissions/create-permissions-group"]}>
							<CreatePermissionsGroupPage />
						</Permissions>
					}
				/>
				<Route
					path="/permissionsGroups/:permissionsGroupId"
					element={
						<Permissions tags={["permissions/read-permissions-tags", "permissions/read-permissions-group"]}>
							<PermissionsGroupPage />
						</Permissions>
					}
				/>

				<Route path="/reports/past" element={<ReportsPage />} />
				<Route path="/reports/past/:reportId" element={<ReportPage />} />

				<Route
					path="/reports/generate/userAttendance"
					element={
						<Permissions tags={["reports/create-user-attendance-report"]}>
							<GenerateUserAttendanceReportPage />
						</Permissions>
					}
				/>

				<Route path="/changePassword" element={<ChangePassword />} />
				<Route path="/verifyEmail" element={<VerifyEmail />} />
				<Route
					path="/departments"
					element={
						<Permissions tags={["departments/read-department"]}>
							<DepartmentsPage />
						</Permissions>
					}
				/>
				<Route
					path="/departments/create"
					element={
						<Permissions tags={["departments/create-department"]}>
							<CreateDepartmentPage />
						</Permissions>
					}
				/>
				<Route
					path="/departments/:departmentId"
					element={
						<Permissions tags={["departments/read-department"]}>
							<DepartmentPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers"
					element={
						<Permissions tags={["readers/read-reader"]}>
							<ReadersPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/:readerId/update"
					element={
						<Permissions tags={["readers/update-reader"]}>
							<UpdateReaderPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/:readerId"
					element={
						<Permissions tags={["readers/read-reader"]}>
							<ReaderPage />
						</Permissions>
					}
				/>
				<Route
					path="/readers/create"
					element={
						<Permissions tags={["readers/create-reader"]}>
							<CreateReaderPage />
						</Permissions>
					}
				/>
				<Route
					path="/tags"
					element={
						<Permissions tags={["tags/read-tag"]}>
							<TagsPage />
						</Permissions>
					}
				/>

				<Route
					path="/tags/create"
					element={
						<Permissions tags={["tags/create-tag"]}>
							<CreateTagPage />
						</Permissions>
					}
				/>

				<Route
					path="/tags/:tagId"
					element={
						<Permissions tags={["tags/read-tag"]}>
							<TagPage />
						</Permissions>
					}
				/>

				<Route
					path="/services"
					element={
						<Permissions tags={["services/read-service"]}>
							<ServicesPage />
						</Permissions>
					}
				/>
				<Route
					path="/services/create"
					element={
						<Permissions tags={["services/create-service"]}>
							<CreateServicePage />
						</Permissions>
					}
				/>
				<Route
					path="/services/:serviceId"
					element={
						<Permissions tags={["services/read-service"]}>
							<ServicePage />
						</Permissions>
					}
				/>
			</Route>
			<Route path="/unauthorized" element={<UnauthorizedPage />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	)
}
